<template>
    <div>
        <div class="head-portrait-header">
            <div class="head-portrait-header-left">
                <img class="head-portrait" :src="userInfo.headPic" alt="">
                <button class="head-portrait-btn">更换头像</button>
            </div>
            <div class="head-portrait-header-right">
                <div class="portrait-header-right-first">
                    <span class="my-bill-name">{{userInfo.nickName}}</span>
                    <span class="my-bill-phone">{{userInfo.phone}}</span>
                </div>
                <div class="portrait-header-right-second">
                    <span class="my-bill-sex-name">性别</span>
                    <span v-if="userInfo.userSex == 0" class="my-bill-sex">男</span>
                    <span v-if="userInfo.userSex == 1" class="my-bill-sex">女</span>
                </div>
                <div class="portrait-header-right-third">
                    <span class="real-name">实名认证</span>
                    <span class="user-status">已通过</span>
                    <!-- <span class="">已通过</span> -->
                    <!-- <a class="de-authentication" href="">去认证</a> -->
                </div>
            </div>
        </div>
        <div >
            <div>
                <el-table
                    :header-cell-style="{textAlign:'center'}"
                    :cell-style="{textAlign:'center'}"
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column
                        prop="contractName"
                        label="房源">
                    </el-table-column>
                    <el-table-column
                        prop="contractPrice"
                        label="月租金(元)">
                    </el-table-column>
                    <el-table-column
                        prop="payType"
                        label="付款方式">
                    </el-table-column>
                    <el-table-column
                        prop="contractPeriod"
                        label="合同租期">
                    </el-table-column>
                    <el-table-column
                        prop="gmtTime"
                        label="合同到期">
                    </el-table-column>
                    <el-table-column
                        prop="contractType"
                        label="状态">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- <div class="pagination-container">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="100"
                layout="total, sizes, prev, pager, next, jumper"
                :total="400">
            </el-pagination>
        </div> -->
    </div>
</template>

<script>
import request from '../../api/index'
export default {
    name:"MyContract",
    data(){
        return{
           tableData:[], 
           userInfo:'',
        }
    },
    mounted() {
        this.getContract()
        this.getUserInfo()
    },
    methods: {
        getContract(){
            let params = {
                    "userId" :JSON.parse(localStorage.getItem('userId')),
                    }
                    request.get('/operating-real-estate/user/mine-contract-list',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.tableData = res.data 
                                console.log("1111"+this.tableData)
                            }
                        }
                    );
        },
        getUserInfo(){
            let params = {
                "userId" : JSON.parse(localStorage.getItem('userId'))
            }
            request.get('/operating-real-estate/user/getUserInfo',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.userInfo = res.data
                            }
                        }
                    );
        }
    },
}
</script>
<style lang="less" scoped>
    .head-portrait-header{
        height: 174px;
        border-bottom: 1px solid #E7E7E7;
        display: flex;
        flex-direction: row;
    }
    .head-portrait{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 30px 0px 10px 40px;
    }
    .head-portrait-btn{
        padding: 0px;
        display: block;
        width: 60px;
        height: 24px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #3094FF;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #3094FF;
        margin-left: 40px;
    }
    .head-portrait-header-left{
        flex: 2;
    }
    .head-portrait-header-right{
        flex: 8;
    }
    .portrait-header-right-first{
        margin: 40px 0px 26px 0px;
    }
    .my-bill-name{
        width: 48px;
        height: 24px;
        font-size: 24px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        margin-right: 20px;
    }
    .my-bill-phone{
        width: 84px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
    .portrait-header-right-second{
        margin-bottom: 20px;
    }
    .my-bill-sex-name{
        width: 28px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-right: 50px;
    }
    .my-bill-sex{
        width: 14px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
    .real-name{
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-right: 22px;
    }
    .user-status{
        width: 42px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #63C465;
        line-height: 14px;
        margin-right: 22px;
    }
    .de-authentication{
        width: 42px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #3094FF;
        line-height: 14px;
    }



    /* 分页区域 */
     .pagination-container {
        text-align: right;
        /* display: inline-block; */
        margin-top: 20px;
        margin-right: 30px;
    }

</style>